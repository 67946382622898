/*eslint-disable*/
import React, {  useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
// components

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";
import AgeVerification from "components/AgeVerification";
import { useCookies } from "react-cookie";

export default function Navbar({fixed}) {
  const history = useHistory();
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['age-verified']);
  const [underAge, setUnderAge] = useState(false)




  useEffect(() => {
    //console.log(cookies['age-verified'])
    if (cookies['age-verified'] == "true") 
    {
      //console.log("verified IN NAV BAR");
      setUnderAge(false)    
    }
    else{
      //console.log("!verified IN NAV BAR",cookies['age-verified']);
      //setShowModal(true);
      setUnderAge(true)
    }  
}, [cookies])



const routeChange = (pathName) =>{ 
  //console.log("routeChanged called ");
  if(!underAge)
  {
    if(pathName=="/memberships")
    {
      let membershipURL = "https://join.mywallet.deals/?id=c437257f-71b2-45a7-9190-3f91cf78de8a&m_location=/#/login"
      window.open(membershipURL, '_blank', 'noopener,noreferrer');
    }
    else if(pathName === "/giftcards"){
      let giftCardURL = "https://card.birchmountnetwork.com/store/tcann"
      window.open(giftCardURL, '_blank', 'noopener,noreferrer');
    }
    else{
      history.push(pathName);
    }
    
  }
  else{
    //console.log("!verified IN NAV BAR",cookies['age-verified']);
  }
}
  return (
    <>  
    
     
      <nav className="top-0  z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-green shadow">
     
        
        <div className="container px-4 md:px-0 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <button
                className=""
                onClick={()=>{routeChange("/")}}                                
              >
          <img
                alt="..."
                className="inline-block navLogo"
                src={require("assets/img/logo.png").default}
              />
            </button>
            <button
              className="w-1/4 cursor-pointer text-xl leading-none px-3 py-1 rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"          
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars text-white"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-green lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="flex items-center">
                <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={()=>{routeChange("/")}}                                   
                >
                  <h1 className="text-white navText">HOME</h1>
                  {/* <span className=" text-white navText lg:hidden inline-block ml-2">HOME</span> */}
                </button>
              </li>

              {/* <li className="flex items-center">
                <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={()=>{routeChange("/stores")}}                                    
                >
                  <h1 className="text-white navText">STORES</h1> */}

                  {/* <span className="lg:hidden inline-block ml-2">Tweet</span> */}
                {/* </button>
              </li> */}

              {/* <li className="flex items-center">
                <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={()=>{routeChange("/giftcards")}}
                >
                  <h1 className="text-white navText">GIFT CARDS</h1> */}

                  {/* <span className="lg:hidden inline-block ml-2">Tweet</span> */}
                {/* </button>
              </li> */}
              
              <li className="flex items-center">
                <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={()=>{routeChange("/memberships")}}                                    
                >
                  <h1 className="text-white navText">MEMBERSHIPS</h1>

                  {/* <span className="lg:hidden inline-block ml-2">Tweet</span> */}
                </button>
              </li>

              <li className="flex items-center">
                <button
                  className="hover:text-blueGray-500 text-blueGray-700 lg:px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  onClick={()=>{routeChange("/contact")}}                  
                >
                  <h1 className="text-white navText">CONTACT</h1>                  

                  {/* <span className="lg:hidden inline-block ml-2">Star</span> */}
                </button>
              </li>

              {/* <li className="flex items-center">
                <button
                style={{paddingTop:"11px",paddingBottom:"11px",paddingLeft:"19px",paddingRight:"19px"
              }}
                            //className="bg-black text-white active:bg-lightBlue-600 navText font-bold uppercase px-4 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  className="text-white bg-lightBlue-600 text-xs font-bold uppercase rounded shadow outline-none focus:outline-none ease-linear transition-all duration-150"
                  type="button"
                  onClick={()=>{routeChange("/order")}}
                >
                  <h1 style={{fontWeight: 700,fontSize: "18px",lineHeight: "24px"}}>Order Now</h1>
                </button>
              </li> */}
            </ul>
          </div>
        </div>
        </nav>
     
      
      
      
    </>
  );
}
