import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { holidayList } from "components/Helpers/HolidayHelper";
import { useCookies } from "react-cookie";

export default function CardLocationItem({locationAddress,locationTitle,locationHours,locationHours2,locationPhoneNumber,locationId})
{

  let history = useHistory();
  const [cookies, setCookie, removeCookie] = useCookies(['location']);
  const [hours,setHours]=React.useState("");
  const [hours2,setHours2]=React.useState("");


{/* <img
                      className="mx-auto py-4 "
                      src={require("assets/img/leafIcon.png").default}
                      alt="..."
                    /> */}



                    useEffect(()=>{
                      
                        setHours(locationHours["regular"])
                        if(locationHours2)
                        {
                          setHours2(locationHours2);
                        }




                      //}
                    },[])


  const goToOrderPage = (locationId) =>
  {
    setCookie('location', locationId, 2592000);
    history.push("/order/");
  }

  return (
    <>
      <div className="bg-white w-full relative flex flex-col min-w-0 break-words bg-white rounded mb-6 xl:mb-0 shadow-lg">
        <div className="flex-auto p-4">
          <div className="flex flex-wrap">
            <div className="relative w-full max-w-full flex-grow flex-1">                                          
              
              <div className=" container flex flex-row">
                <div className="container mx-auto">
                  <div className="flex flex-col md:flex-row text-left md:text-center items-center md:pt-0 py-2">                               
                    <div className="">
                      <img
                          alt="..."                
                          style={{height:"100%",width:"100%",maxWidth:"80px"}}

                          src={require("assets/img/logo2.png").default}
                        />              
                    </div>
                    <div className="px-2 text-xl md:pt-0 text-black font-semibold" style={{fontWeight: 500,fontSize: "24px",lineHeight: "29px",textTransform: "capitalize"}}>
                      <p>{locationTitle}</p>            
                    </div>            
                  </div>        
                </div>
              </div>            
              {/* <div className="w-full font-semibold text-sm leading-relaxed text-black py-2" style={{fontWeight: 700,fontSize: "16px",lineHeight: "20px",textTransform: "capitalize"}}>
                {locationAddress}
              </div> */}
              <div className="block font-semibold text-sm leading-relaxed text-black " style={{fontWeight: 700,fontSize: "16px",lineHeight: "20px",textTransform: "capitalize"}}>
                <a target={"_blank"} href={"http://maps.google.com/?q="+ locationAddress}>
                  {locationAddress}                
                </a>
              </div>
              {//locationId !=="t-cannabis-sioux-lookout" &&}
              <>
                <div className="font-semibold text-sm leading-relaxed text-black py-2" style={{fontWeight: 500,fontSize: "16px",lineHeight: "20px",textTransform: "capitalize"}}>
                  
                  <a href={"tel:"+locationPhoneNumber}>{locationPhoneNumber}</a>
                </div>
                
                <div className="font-semibold text-sm leading-relaxed text-black" style={{fontWeight: 500,fontSize: "16px",lineHeight: "20px",textTransform: "capitalize"}}>Hours:</div>
                
                
                <div className=" pb-2 font-bold text-sm leading-relaxed" style={{color:"#E95094",fontWeight: 700,fontSize: "16px",lineHeight: "20px",textTransform: "capitalize"}}>
                  {hours}
                </div>
                {hours2 &&

                  <div className=" pb-2 font-bold text-sm leading-relaxed" style={{color:"#E95094",fontWeight: 700,fontSize: "16px",lineHeight: "20px",textTransform: "capitalize"}}>
                  {hours2}
                  </div>

                }
                

                <div className="pt-2 font-semibold text-xl text-blueGray-700">
                  
                  <button 
                  onClick={()=>{goToOrderPage(locationId)}}
                  className="w-full get-started text-white font-bold  py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                    Order Now
                  </button>
                </div>              
              </>
               } 
               {/* {locationId =="t-cannabis-sioux-lookout" &&
                <button 
                onClick={()=>{}}
                className="w-full get-started text-white font-bold  py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150">
                  Coming Soon
                </button>
              }  */}
              
            </div>
          
          </div>        
        </div>
      </div>
    </>
  );
}

CardLocationItem.defaultProps = {
  locationAddress: "Traffic",
  locationTitle: "350,897",
  statArrow: "up",
  statPercent: "3.48",
  statPercentColor: "text-emerald-500",
  locationHours: "Since last month",
  statIconName: "far fa-chart-bar",
  statIconColor: "bg-red-500",
};

CardLocationItem.propTypes = {
  locationAddress: PropTypes.string,
  locationTitle: PropTypes.string, 
  locationHours: PropTypes.string, 
};
