import React, {  useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getAnalytics, logEvent } from "firebase/analytics";

export default function Footer() {
  const [cookies, setCookie, removeCookie] = useCookies(['age-verified']);
  const [underAge, setUnderAge] = useState(false)
  const history = useHistory();


  const analytics = getAnalytics();




  useEffect(() => {
    //console.log(cookies['age-verified'])
    if (cookies['age-verified'] == "true") 
    {
      //console.log("verified IN NAV BAR");
      setUnderAge(false)    
    }
    else{
      //console.log("!verified IN NAV BAR",cookies['age-verified']);
      //setShowModal(true);
      setUnderAge(true)
    }  
}, [cookies])



const routeChange = (pathName) =>{ 
  //console.log("routeChanged called ");
  if(!underAge)
  {
    logEvent(analytics, 'route_change', { "path": pathName});
    
    if(pathName=="/memberships")
    {
      let membershipURL = "https://join.mywallet.deals/?id=c437257f-71b2-45a7-9190-3f91cf78de8a&m_location=/#/login"
      window.open(membershipURL, '_blank', 'noopener,noreferrer');
    }    
    else if(pathName === "/giftcards"){
      let giftCardURL = "https://card.birchmountnetwork.com/store/tcann"
      window.open(giftCardURL, '_blank', 'noopener,noreferrer');
    }
    else if(pathName=="/careers")
    {      
      let careersURL = "https://docs.google.com/forms/d/e/1FAIpQLSfeqmotU-7Ko8SFYVzBHIp4JgnF3APcWB2yKpi_LpiBHzj2zA/viewform"
      window.open(careersURL, '_blank', 'noopener,noreferrer');
    }    
    else if(pathName=="/facebook")
    {      
      let fbURL = "https://www.facebook.com/TCANN.CA/"
      window.open(fbURL, '_blank', 'noopener,noreferrer');
    }    
    else if(pathName=="/instagram")
    {      
      let igURL = "https://www.instagram.com/tcannabisstore/"
      window.open(igURL, '_blank', 'noopener,noreferrer');
    }    
    else if(pathName=="/twitter")
    {      
      let tURL = "https://twitter.com/TCANNABIS2"
      window.open(tURL, '_blank', 'noopener,noreferrer');
    }    
    else
    {
      history.push(pathName);
    }
  }
  else{
    //console.log("!verified IN NAV BAR",cookies['age-verified']);
  }
}

  return (
    <>
      
      <div className="bottom-auto relative bg-green">        
        <div className="mx-auto w-full">
          <hr className="border-blueGray-300" />

          <div className="container mx-auto pt-2 px-2 flex  justify-start md:justify-between w-full flex-col md:flex-row text-left md:text-center md:items-center " style={{fontWeight: 400,fontSize: "18px",lineHeight: "30px"}}>           
            {/* <div className="w-full"> */}
            {/* t can logo */}
            <div className="">

              <img
                  alt="..."
                  className="w-6/12 md:w-full lg:w-full footerCCLogo"                
                  src={require("assets/img/logo.png").default}
                />              
            </div>
            {/* links */}
            
            <div className="flex items-center">
              <div className="flex-row ">
                <div className="flex md:w-1/4 md:mx-auto flex-row text-left md:text-center md:justify-between items-center md:pt-0" style={{fontWeight: 400,fontSize: "18px",lineHeight: "30px"}}>           
                
                  {/* twitter logo */}
                  <div className="pt-2  md:pt-0 text-white font-normal">              
                    <div>
                      <button onClick={()=>{routeChange("/twitter")}}>
                      <img
                        alt="..."
                        className="w-6/12 md:w-full lg:w-full socialIcon"                
                        src={require("assets/img/TIcon.png").default}
                      />              

                      </button>
                    </div>            
                  </div>

                  {/* facebook link */}
                  <div className="pt-2 px-2 md:pt-0 text-white font-normal">
                    <div>
                      <button
                        className=""
                        onClick={()=>{routeChange("/facebook")}}                  
                      >
                        <img
                          alt="..."
                          className="w-6/12 md:w-full lg:w-full socialIcon"                
                          src={require("assets/img/FBIcon.png").default}
                        />              

                      </button>
                      </div>            
                  </div>
                  
                  {/* instagram */}
                  <div className="pt-2 md:pt-0 md:pl-0 text-white font-normal ">
                    <div>
                      <button
                        className=""
                        onClick={()=>{routeChange("/instagram")}}
                        
                      >
                        <img
                            alt="..."
                            className="md:w-full lg:w-full socialIcon"                
                            src={require("assets/img/IGIcon.png").default}
                          />              

                      </button>

                    </div>            
                  </div>
            
                </div>
                <div className="flex flex-col md:justify-between md:flex-row text-left md:text-center items-center md:pt-0">
                  <div className="w-full pt-2 md:pt-0 text-white font-normal  md:pl-0 lg:pl-0">              
                    <div>
                      <button onClick={()=>{routeChange("/")}}>
                      <h1 className="mobileFooterTextSize md:noWrap"> &#62; Home</h1>
                      </button>
                    </div>            
                  </div>
                  {/* <div className="w-full pt-2 md:pt-0 text-white font-normal  md:pl-0 lg:pl-0">
                    <div>
                      <button
                        className=""
                        onClick={()=>{routeChange("/order")}}
                        
                      >
                      <h1 className="mobileFooterTextSize md:noWrap" > &#62; Order Now</h1>
                      </button>
                      </div>            
                  </div> */}
                  {/* <div className="w-full pt-2 md:pt-0 md:pl-0 text-white font-normal  lg:pl-0">
                    <div>
                      <button
                        className=""
                        onClick={()=>{routeChange("/stores")}}
                        
                      >
                      <h1 className="mobileFooterTextSize md:noWrap"> &#62; Stores</h1>
                      </button>

                    </div>            
                  </div> */}
                  <div className="w-full pt-2 md:pt-0 md:pl-0 text-white font-normal  lg:pl-0">
                    <div>
                      <button
                        className=""
                        onClick={()=>{routeChange("/contact")}}
                        
                      >
                      <h1 className="mobileFooterTextSize md:noWrap"> &#62; Contact</h1>
                      </button>
                    </div>            
                  </div>
                  <div className="w-full pt-2 md:pt-0 text-white font-normal md:pl-0 lg:pl-0">
                    <div>
                      <button
                        className=""
                        onClick={()=>{routeChange("/memberships")}}
                      >
                      <h1 className="mobileFooterTextSize md:noWrap">&#62; Membership</h1>
                      </button>
                      </div>            
                  </div>
                  <div className="w-full pt-2 md:pt-0 text-white font-normal md:pl-0 lg:pl-0">
                    {/* <div>
                      <button
                        className=""
                        onClick={()=>{routeChange("/giftcards")}}
                      >
                      <h1 className="mobileFooterTextSize md:noWrap">&#62; Gift Cards</h1>
                      </button>
                      </div>             */}
                  </div>
                  <div className="w-full pt-2 md:pt-0 text-white font-normal  md:pl-0 lg:pl-0">
                    <div>
                      <button
                        className="mobileFooterTextSize"
                        onClick={()=>{routeChange("/careers")}}
                        target="_blank"
                      >
                        <h1 className="mobileFooterTextSize md:noWrap"> &#62; Careers</h1>
                      </button>
                    </div>            
                  </div>
                </div>
              </div>
            </div>
            
          
            
            {/* <div className="w-full md:w-6/12 pt-2 md:pt-0"> */}
            {/* ontario authorized logo */}
            <div className="flex pt-2 md:pt-0 items-center">
              <img
                  alt="..."                       
                  className="footerCCLogo float-left md:w-full lg:w-full md:float-right"         
                  src={require("assets/img/authENFR1.png").default}
                />              
            </div>
          </div>
          
          <hr className="my-6 border-blueGray-300" />
          
          <div className="flex container mx-auto flex-col md:flex-row">                       
            <div className="sm:text-sm md:w-7/12 text-white text-center md:text-left">
              <p>© Copyright Epic Milff. 2022-23.
              <span className="md:hidden"> <br/></span>All right reserved.</p>            
            </div>
            
            <div className="w-full md:w-5/12 flex text-center lg:text-right justify-between">
              <div className="md:px-3 text-white w-full">
                <p>Privacy Policy</p>            
              </div>
              <div className="md:px-3 text-white w-full">
                <p>Sitemap</p>            
              </div>
              <div className="md:px-3 text-white w-full ">
                <p>Terms Of Use</p>            
              </div>
            </div>
            
          </div>
        
        </div>
      </div>
    
    </>
    
  );
}
