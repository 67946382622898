

import React, {  useEffect, useState } from "react";

import { useCookies } from "react-cookie";


export default function AgeVerification(){
    
        const [showModal, setShowModal] = React.useState(false);
        const [open, setOpen] = React.useState(true);
        const [rememberMe, setRememberMe] = useState(false);
        const [cookies, setCookie, removeCookie] = useCookies(['age-verified']);
        const [underAge, setUnderAge] = useState(false)
    
    
        const handleAgeVerified = (valid) => {
          if(valid)
          {
            //console.log("old enough");
            setCookie('age-verified', true, { maxAge: rememberMe ? 2592000 : 90000 });
            setShowModal(false);
            setUnderAge(false);
          }
          else{
            setUnderAge(true);
            alert("Please leave and come back when you are of age.Thank you");
          }                    
        }
        
        useEffect(() => {
          //console.log(cookies['age-verified'])
          if (cookies['age-verified'] == "true") 
          {
            //console.log("verified");
          
          }
          else{
            //console.log("!verified",cookies['age-verified']);
            setShowModal(true);
          }  
      }, [cookies])
       
      useEffect(() => {
          document.body.style.overflow = 'hidden';
          return ()=> document.body.style.overflow = 'unset';
      }, []);
          
      useEffect(() => {
        showModal && (document.body.style.overflow = 'hidden');
        !showModal && (document.body.style.overflow = 'unset');
      }, [showModal ]);
    
        
      return (
        <>         

          {showModal ? (
            <>
              <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
              >
                 <div className="relative  w-auto my-6 mx-auto w-10/12 md:w-10/12 lg:w-10/12 xl:w-4/12">
                  {/*content*/}
                  <div className="border-0 bg-white rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none ocmOutline">
                    {/* Logo Banner */}
                    <div className="flex items-start justify-center p-5 rounded-t">                      
                      <img
                        alt="..."
                        className="inline-block w-6/12"
                        src={require("assets/img/logo2.png").default}
                      />
                    </div>

                    {/*header*/}
                    {/* <div className="flex items-start justify-between p-5 rounded-t">                      
                      <h3 className="text-black" style={{fontWeight: 700,fontSize: "24px"}}>
                        ONLY BECAUSE WE HAVE TO ASK
                      </h3>
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                      >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                    </div> */}
                    {/*body*/}
                      <div className="relative py-2 px-6 flex-auto">
                      <div className="pb-2">
                        <h3 className="text-black text-center" style={{fontWeight: 700,fontSize: "24px"}}>
                          ONLY BECAUSE WE HAVE TO ASK
                        </h3>
                      </div>
                      <div className="pb-2">
                          {/* <p className="text-blueGray-500" style={{fontWeight: 600,fontSize: "19px"}}> */}
                          <p className="text-black text-center" style={{fontWeight: 600,fontSize: "19px"}}>
                          BEFORE ENTERING THIS WEBSITE YOU MUST BE OF LEGAL AGE IN ACCORDANCE WITH THE LAW OF YOUR PROVINCE
                          </p>
                      </div>
                        

                      <div className="pt-1rem" style={{display:"inline"}} >
                        <input id="avcb" type="checkbox" defaultChecked={true}/> <p  className="text-black" style={{display:"inline",fontWeight: 600,fontSize: "14px"}}>Remember Me For 30 Days</p>
                      </div>

                      <div className="flex flex-col md:flex-row items-center pt-1rem rounded-b">                      
                          <button
                            className="order-1 tcantheme md:order-1 w-full bg-green text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mb-1 ease-linear transition-all duration-150 xl:mr-1 md:mr-1 lg:mr-1"
                            type="button"
                            style={{  whiteSpace: "nowrap",border: "1px solid #000000",borderRadius: "9px",fontSize:"18px"}}
                            onClick={() => handleAgeVerified(true)}
                          >
                            Yes, I'M LEGAL AGE
                          </button>
                          <button
                            className="order-2 md:order-2 w-full text-white font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none  mb-1 ease-linear transition-all duration-150 xl:ml-1 lg:ml-1 md:ml-1"
                            type="button"
                            style={{whiteSpace: "nowrap",background: "#000000",border: "1px solid #000000",borderRadius: "9px",fontSize:"18px"}}
                            onClick={() => handleAgeVerified(false)}
                          >
                            NO, I'M NOT
                          </button>
                      </div>
                    </div>
                    {/*footer*/}
                      {/* Logo Banner */}
                      <div className="flex items-start justify-center p-5 rounded-t">                      
                        <img
                            alt="..."                       
                            className="w-1/2 md:w-1/4"         
                            src={require("assets/img/authENFR2.png").default}
                          />                      
                    </div>
                  </div>
                </div>
              </div>
              <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
          ) : null}
          {!showModal &&
          <></>
          }
        </>
      );
    }