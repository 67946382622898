/*eslint-disable*/
import React, { useEffect, useState, useRef } from "react";
import "../assets/styles/styles.css";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import AgeVerification from "components/AgeVerification";
import { useHistory } from "react-router-dom";
import StockBanner from "components/StockBanner/StockBanner";
import ReactGA from "react-ga";
import { getAnalytics, logEvent } from "firebase/analytics";
import mobileBackground from "assets/img/bannerBGmobile.png";
import desktopImage from "assets/img/bannerBGdesktop.png";

export default function Index() {
  ReactGA.initialize("G-VDBYX7BGL8");

  const analytics = getAnalytics();

  const [showModal, setShowModal] = React.useState(false);
  const [open, setOpen] = React.useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["age-verified"]);
  const [underAge, setUnderAge] = useState(false);
  let history = useHistory();
  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView();
  // handle scroll down
  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    //console.log(cookies['age-verified'])
    if (cookies["age-verified"] == true) {
      //console.log("verified");
    } else {
      //console.log("!verified");
    }
  }, [cookies]);

  const routeChange = (pathName) => {
    //console.log("routeChanged called ");
    if (!underAge) {
      //getAnalytics(getApp()).logEvent('user_engagement', "homepage_click",pathName);
      logEvent(analytics, "user_engagement", { page_click: pathName });
      if (pathName == "/membership") {
        let membershipURL =
          "https://join.mywallet.deals/?id=c437257f-71b2-45a7-9190-3f91cf78de8a&m_location=/#/login";
        window.open(membershipURL, "_blank", "noopener,noreferrer");
      } else if (pathName == "/careers") {
        let careersURL =
          "https://docs.google.com/forms/d/e/1FAIpQLSfeqmotU-7Ko8SFYVzBHIp4JgnF3APcWB2yKpi_LpiBHzj2zA/viewform";
        window.open(careersURL, "_blank", "noopener,noreferrer");
      } else {
        history.push(pathName);
      }
    } else {
      //console.log("!verified IN NAV BAR",cookies['age-verified']);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const backgroundImage = isMobile ? mobileBackground : desktopImage;

  return (
    <>
      {/* <AgeVerification></AgeVerification> */}

      <IndexNavbar fixed />
      <StockBanner></StockBanner>

      <section
        ref={myRef}
        className="header h-screen px-12 relative items-center flex flex-wrap backgroundImage"
        // style={{
        //   minHeight: "400px",
        //   backgroundImage: `url(${isMobile ? mobileImage : desktopImage})`,
        //   backgroundSize: "cover",
        // }}
      >
        <div className="container mx-auto ">
          <div className="flex flex-col items-center justify-center">
            <div className="text-center">
              <h3 className="text-3xl font-semibold text-black">
                Enroll In Our Membership Program
              </h3>
              <p className="mt-4 text-lg leading-relaxed text-black">
                Start Earning And Redeeming Awesome Rewards Today!
              </p>
            </div>

            <div className="w-full">
              <div className="flex-auto mx-auto items-center text-center pt-3">
                <button
                  onClick={() => {
                    routeChange("/membership");
                  }}
                  style={{ width: "250px" }}
                  className="ocmOutline w-full get-started text-white font-bold py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue2-500 active:bg-lightBlue2-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                >
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      {
        // <!-- Hotjar Tracking Code for my site -->

        (function (h, o, t, j, a, r) {
          h.hj =
            h.hj ||
            function () {
              (h.hj.q = h.hj.q || []).push(arguments);
            };
          h._hjSettings = { hjid: 3251138, hjsv: 6 };
          a = o.getElementsByTagName("head")[0];
          r = o.createElement("script");
          r.async = 1;
          r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
          a.appendChild(r);
        })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=")
      }
    </>
  );
}
