import React,{  useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import StoreList from "components/Helpers/StoreList";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Icon } from "@mui/material";
import { distance } from "components/Helpers/functions";
//import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import ReactGA from 'react-ga';
import { getApp, getApps } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

const useStyles = makeStyles({
  textFieldRoot: {
    "& > div.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']": {
      // default paddingRight was 39px since clear icon was positioned absolute
      paddingRight: "9px", 
      paddingTop:0,
      paddingBottom:0,
      // Search icon
      "& button": {
        order: 3, // order 3 means the search icon will appear after the clear icon which has an order of 2
      },

      // Clear icon
      "& > div.MuiAutocomplete-endAdornment": {
        position: "relative", // default was absolute. we make it relative so that it is now within the flow of the other two elements
        order: 2,
      },
    },
  },
});

export default function StoreSearchBar({listOfStores,setListOfStores,setMapCenter}) {
    const classes = useStyles();

    const [name,setName]=useState("");
    const [email,setEmail]=useState("");
    const [text,setText]=useState("");
    

const analytics = getAnalytics();

    //const [listOfStores,setlistOfStores]=useState([]);

    const [locations,setLocations]=useState([]);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {
    if(locations.length == 0 )
    {
        let storelist = listOfStores.map((store)=>{
            //console.log(store.title);
            return {title:store.title,id:store.id};
        })
        setLocations(storelist);
    }

    }, [])

    

    const [selected, setSelected] = useState("");

    useEffect(() => {
        
        
        
        if(text)
        {
            if(text.length == 0 && listOfStores.length==0)
            {
                setListOfStores(StoreList)
            }
            if(text.length>0 && listOfStores.length>0)
            {
                //assume no match
                //console.log("i assume there is no match");

            }
        }

        
    }, [text])


    //an object gets passed in to
    const getSearchResult = (search) =>
    {
        if(search!=null)
        {
            StoreList.forEach((store)=>{
                if(search.title == store.title)
                {
                    //console.log("match")                
                    setListOfStores([store]);
                    //console.log("SB-list of stores set to",listOfStores)
                    return true;
                }                 
            })        
        }
        
        return false;
    }

        
    //return the searched store when the submit button is hit
    const searchStoreList = (loc) =>{

        //console.log("looking for",loc,selected,text);
        
        //getSearchResult(loc);
        handleChange({title:text[0].toUpperCase() + text.slice(1).toLowerCase()})
        
        //



    }
    
    //this executes when a user selects a store fron the autocomplete drop down list
    const handleChange = (selectedStore) =>{
        //console.log("ACDDL looking for",selectedStore);      
        if(selectedStore == null || selectedStore == undefined)  
        {
            setListOfStores(StoreList);
        }
        //update underlying text obj
        setText(selectedStore)

        //store found
        if(getSearchResult(selectedStore))
        {
            //console.log("match");  
                      
            //getAnalytics(getApp()).logEvent('user_engagement', "store_search",selectedStore);
            logEvent(analytics, 'user_engagement', { "store_search": selectedStore});
            
            ReactGA.event({
                category: 'user_engagement',
                action: 'store_search',
                value: selectedStore
            });
        }     
        else{
            //console.log("no match");            
            //setListOfStores([]);
            //console.log(listOfStores)
           // setText({title:""});

        }

        
    }
    
    const setDistances = (usersLat,usersLng) =>
    {
        listOfStores.forEach((store)=>{
            let dist = distance(usersLat,usersLng,store.location.lat,store.location.lng);
            store.distance = dist
        })
        //console.log(listOfStores);
        
    }

    const enableLocation = () =>{
        navigator.geolocation.getCurrentPosition(function(position) {
            //console.log("Latitude is :", position.coords.latitude);        
            //console.log("Longitude is :", position.coords.longitude);
            let lat =position.coords.latitude;        
            let lng =position.coords.longitude; 
            //sort list based on location proximity
            setDistances(lat,lng);
            //listOfStores.sort((a,b) => (a.distance > b.distance) ? 1 : ((b.distance > a.distance) ? -1 : 0))
            //console.log(listOfStores)
            // listOfStores.sort((a,b) => {
            //     //console.log(a.distance,b.distance)
            //     return a.distance - b.distance
            // });
            let los = [...listOfStores].sort((a, b) => {
                if (a.distance > b.distance) {
                  return 1;
                }
                if (a.distance < b.distance) {
                  return -1;
                }
                return 0;
              });
            //console.log(los)
            setListOfStores(los);
            setMapCenter({"lat":los[0].location.lat,"lng":los[0].location.lng})
            //getAnalytics(getApp()).logEvent('user_engagement', "location_enabled","true");
            logEvent(analytics, 'user_engagement', { "location_enabled": "true"});

        });                
    }



    return (
    <>
        <div className="flex flex-row justify-between items-center">                    
            {/* <div className="w-8/12 md:w-8/12 " style={{paddingRight:"0.5rem"}}> */}
            <div className="w-full">

                {/* <input type="text" id="name" onChange={handleChangeName} style={{fontWeight: 700,fontSize: "18px",lineHeight: "24px"}} className="shadow-sm bg-gray-50 font-semibold border border-gray-300   rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500 dark:shadow-sm-light" placeholder="Name" required/> */}

                <Autocomplete
                // value={text}
                    popupIcon={""}
                    freeSolo={false}
                    // disableClearable
                    disableUnderline="true"
                    options={locations}
                    getOptionLabel={(option) => option.title}
                    onChange={(event, selectedValue) => handleChange(selectedValue)}  
                    sx={{// border: "1px solid blue",
                        "& .MuiOutlinedInput-root": {
                            // border: "1px solid yellow",
                            border:"none",
                            padding: "0"
                        },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                            border:"none"
                        },          
                        padding:0,
                        width: "100%",
                        background: "#FFFFFF",
                        borderRadius: "17px"}}                    
                    //options={listOfStores.map((option) => option.title)}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        classes={{
                            root: classes.textFieldRoot, // apply class here
                        }}
                        placeholder="Location"
                        InputProps={{
                        ...params.InputProps,
                        type: 'search',
                        endAdornment: (
                            // <InputAdornment position="end">
                                <React.Fragment>
                                <IconButton onClick={()=>{enableLocation()}}>
                                    <img
                                        className="items-center"
                                        style={{height:"100%",width:"100%",maxWidth:"30px"}}
                                        src={require("assets/img/locationIcon.png").default}
                                        alt="enable location"
                                    />
                                </IconButton>
                                {params.InputProps.endAdornment}
                                </React.Fragment>
                            // {/* </InputAdornment> */}
                        ),                                                
                        }}
                        onChange={(ref) => handleChange(ref.target.value)} 
                        className="text-center items-center font-semibold  block w-full "  

                    />
                    )}
                />
            </div>

            {/* <div className="w-4/12 md:w-4/12">
                <button onClick={()=>{searchStoreList(locations)}} className="my-2 w-full get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1  uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150" style={{backgroundColor:"#AC7AF7"}}>Search</button>
            </div> */}
        </div>                        
    </>
    );
}
