import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// views without layouts

import ContactUs from "views/ContactUs.js";
import Index from "views/Index.js";
import AgeVerification from "components/AgeVerification";
import Stores from "views/Stores";
import Order from "views/Order";
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import ReactGA from "react-ga";
// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  apiKey: "AIzaSyBypWxFMBSvJSLgpe2OE1XDPGtClIonKFs",

  authDomain: "epicmilff.firebaseapp.com",

  projectId: "epicmilff",

  storageBucket: "epicmilff.appspot.com",

  messagingSenderId: "1056725552270",

  appId: "1:1056725552270:web:f9f57c31686f6aac640ddf",

  measurementId: "G-2R5R81PFTW",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const analytics = getAnalytics(app);
ReactGA.initialize("G-2R5R81PFTW");
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes without layouts */}
      <Route path="/contact" exact component={ContactUs} />
      {/* <Route path="/stores" exact component={Stores} />     */}
      {/* <Route path="/order" exact component={Order} />     */}
      {/* <Route path="/order" component={Order} />     */}
      <Route path="/" exact component={Index} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
